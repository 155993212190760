/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import http from '@utils/http';
import { ACTIONS } from './editor_reducer';

import {
  thumbsContainerStyle, thumbStyle, thumbInnerStyle, imgStyle,
} from './drag_drop_styles';

function LogoForm(props) {
  const { logo, contentId, dispatch } = props;
  const [files, setFiles] = useState(
    logo.tile_url ? [{
      name: 'preview',
      preview: logo.tile_url,
    }] : [],
  );

  const fetchPreview = () => {
    http.get(`/editor/contents/${contentId}/sections/preview_list`)
      .then((response) => dispatch({ type: ACTIONS.SET_PREVIEW_LIST, payload: { list: response.data.sections } }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 1,
    multiple: false,
    maxSize: 1000000,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
    onDropAccepted: (acceptedFiles) => {
      const formData = new FormData();
      formData.append('content[logo]', acceptedFiles[0]);
      http.put(`/editor/contents/${contentId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          dispatch({ type: ACTIONS.SET_LOGO, payload: { logo: response.data.logo } });
          fetchPreview();
        }).catch((error) => {
          if (error.response.status === 422) {
            dispatch({ type: ACTIONS.SET_LOGO, payload: { logo: { errors: error.response.data.errors.logo } } });
          } else {
            throw error;
          }
        });
    },
  });

  const removeTemplate = () => {
    http.delete(`/editor/contents/${contentId}/delete_logo`)
      .then(() => {
        dispatch({ type: ACTIONS.SET_LOGO, payload: { logo: { tile_url: null } } });
        setFiles([]);
        fetchPreview();
      });
  };

  const thumbs = files.map((file) => (
    <div style={thumbStyle} key={file.name}>
      <div style={thumbInnerStyle}>
        <img
          src={file.preview}
          style={imgStyle}
          alt=""
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="mb-4">
      <div className="mb-2 side-header">Logo</div>

      <div {...getRootProps({ className: 'dropzone' })}>
        <input id="logoImg" name="logoImg" {...getInputProps()} />
        <div className="file-label">
          <p>
            Drag & Drop or
            {' '}
            <span>Browse</span>
          </p>
          png, jpg, size: max 1MB
        </div>
      </div>
      {logo.errors && (
        <span className="input-error">{logo.errors[0]}</span>
      )}
      <div className="template-preview">
        <aside style={thumbsContainerStyle}>
          {thumbs}
        </aside>
        {(logo.tile_url || files.length > 0) && (
          <button type="button" className="btn btn-light" onClick={removeTemplate}>
            <svg className="quiz-type">
              <use xlinkHref="#deleteIcon" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default LogoForm;
