import React from 'react';
import useCurrentWidth from '../../hooks/useCurrentScreenWidth';

function Navigation(props) {
  const { verifyContent, errors, contentId } = props;
  const width = useCurrentWidth();
  const errorItems = errors.map((error) => (
    <div className="alert alert-danger" role="alert" key={error}>
      {error}
    </div>
  ));

  const navButtons = (
    <div className={`nav-buttons ${width <= 550 ? 'py-2' : ''}`}>
      <a
        className="btn btn-outline-primary me-3"
        href={`/content_previews/${contentId}`}
        target="_blank"
        rel="noreferrer"
      >
        <svg className="preview-icon">
          <use xlinkHref="#previewIcon" />
        </svg>
      </a>
      <button type="button" className="btn btn-primary min-width-120" onClick={verifyContent}>
        Next
      </button>
    </div>
  );

  return (
    <>
      {errorItems}
      <div className="d-flex editor-nav with-border">
        <div className="active">
          <svg className="build-icon me-3">
            <use xlinkHref="#buildIcon" />
          </svg>
          Build
        </div>
        <div>
          <button type="button" onClick={verifyContent}>
            Settings
          </button>
        </div>
        <div title="Coming soon...">Reports</div>
        {width > 550 ? navButtons : null}
      </div>
      {width <= 550 ? navButtons : null}
    </>
  );
}

export default Navigation;
