import React from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import Dropdown from '../shared/dropdown';

const DragHandle = sortableHandle(({ fixed }) => (
  <svg className="quiz-type" style={{ cursor: 'grab', opacity: fixed ? 0 : 1, pointerEvents: fixed ? 'none' : 'auto' }}>
    <use xlinkHref="#dragIcon" />
  </svg>
));

const SortableItem = sortableElement(({
  section,
  setActivateSectionById,
  activeSectionId,
  sectionTitle,
  deleteSection,
  duplicateSection,
}) => (
  <li className={section.id === activeSectionId ? 'd-flex active' : 'd-flex'}>
    <div className="p-2">
      <DragHandle fixed={section.fixed} />
    </div>
    <div className="d-flex">
      <button type="button" aria-hidden className="p-2 text-start" onClick={() => { setActivateSectionById(section.id); }}>
        {sectionTitle}
      </button>
      { section.type !== 'ending' && (
        <Dropdown
          id={`view-${section.id}-dropdown`}
          wrapperClasses="ms-auto"
          togglerClasses="btn"
          togglerLabel={(
            <svg className="">
              <use xlinkHref="#moreIcon" />
            </svg>
          )}
          menuPosition="end"
        >
          <button type="button" className="btn dropdown-item" onClick={() => duplicateSection(section.id)}>
            <svg className="quiz-type me-3">
              <use xlinkHref="#duplicateIcon" />
            </svg>
            Duplicate
          </button>
          {!section.fixed && (
            <button type="button" className="btn dropdown-item" onClick={() => deleteSection(section.id)}>
              <svg className="quiz-type me-3">
                <use xlinkHref="#deleteIcon" />
              </svg>
              Delete
            </button>
          )}
        </Dropdown>
      )}
    </div>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => <ul className="px-0 views-list">{children}</ul>);

function SectionsList(props) {
  const {
    sections,
    activeSectionId,
    setActivateSectionById,
    setSectionsPositions,
    addSection,
    deleteSection,
    duplicateSection,
  } = props;

  const handleAddSection = (type) => {
    addSection(type);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSectionsPositions(oldIndex, newIndex);
  };

  const sectionTitle = (section) => {
    switch (section.type) {
      case 'welcome': return <span>👋 Welcome Screen</span>;
      case 'question': return <span>{`${section.type_index}. Question`}</span>;
      case 'ending': return <span>🥳 Ending Screen</span>;
      case 'product_placement': return <span>🍔 Product Placement (CTA)</span>;
      default: throw Error('Unsupported type of section');
    }
  };

  return (
    <>
      <SortableContainer
        onSortEnd={onSortEnd}
        lockAxis="y"
        useDragHandle
        helperClass="draggable-item"
      >
        {sections.map((section, index) => (
          <SortableItem
            key={`item-${section.id}`}
            index={index}
            section={section}
            activeSectionId={activeSectionId}
            setActivateSectionById={setActivateSectionById}
            sectionTitle={sectionTitle(section)}
            deleteSection={deleteSection}
            duplicateSection={duplicateSection}
            disabled={section.fixed}
            collection={section.fixed ? `fixed_${section.id}` : 'sortable'}
          />
        ))}
      </SortableContainer>
      <Dropdown
        id="add-view"
        togglerClasses="btn btn-outline-primary w-100"
        togglerLabel="+ Add View"
        wrapperClasses="d-flex"
        paddedMenu
      >
        <button type="button" className="btn btn-outline-secondary w-100" onClick={() => handleAddSection('welcome')}>👋 Welcome Screen</button>
        <button type="button" className="btn btn-outline-secondary w-100" onClick={() => handleAddSection('question')}>
          <svg className="quiz-type me-3">
            <use xlinkHref="#questionIcon" />
          </svg>
          Question
        </button>
        <button type="button" className="btn btn-outline-secondary w-100" onClick={() => handleAddSection('product_placement')}>🍔 Product Placement (CTA)</button>
      </Dropdown>
      <input type="checkbox" defaultChecked name="close-note" id="close-note" onChange={null} />
      {/* TODO: restore this box when more plans will be available */}
      {/* <div className="at-bottom notice-box">
        <label htmlFor="close-note" className="close">
          <svg className="icon-close" preserveAspectRatio="xMidYMid meet" viewBox="0 0 25 25">
            <use xlinkHref="#closeIcon" />
          </svg>
        </label>
        <div className="boost" />
        <h3>Need some boost?</h3>
        <p>You can always Upgrade your plan.</p>
        <a href="/plans" target="_blank" className="btn btn-primary w-100">Upgrade</a>
      </div> */}
    </>
  );
}

export default SectionsList;
