import { setIndexByParam } from '@utils/helpers';
import arrayMove from 'array-move';
import { sortBy } from 'lodash';

const ACTIONS = {
  SET_MODE: 'set_mode',
  SET_ACTIVE_SECTION: 'set_active_section',
  SET_ACTIVE_SECTION_BY_ID: 'set_active_section_by_id',
  SET_PREVIEW_LIST: 'set_preview_list',
  SET_PREVIEW_ITEM: 'set_preview_item',
  SET_SECTION_POSITIONS: 'set_section_positions',
  EDIT_DESIGN: 'edit_design',
  EDIT_ACTIVE_SECTION: 'edit_active_section',
  ADD_SECTION: 'add_section',
  DELETE_SECTION: 'delete_section',
  SET_QUESTION_OPTION: 'set_question_option',
  ADD_QUESTION_OPTION: 'add_question_option',
  DELETE_QUESTION_OPTION: 'delete_question_option',
  SET_QUESTION_OPTION_POSITIONS: 'set_question_option_positions',
  SET_TEMPLATE: 'set_template',
  SET_SECTION_BACKGROUND: 'set_section_background',
  SET_LOGO: 'set_logo',
  SET_BASE_ERRORS: 'set_base_errors',
};

function editorReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_MODE:
      return { ...state, mode: action.payload.mode };

    case ACTIONS.SET_ACTIVE_SECTION:
      return { ...state, activeSection: action.payload.section };

    case ACTIONS.SET_ACTIVE_SECTION_BY_ID: {
      const newSections = state.sections.map((section) => (
        section.id === state.activeSection.id ? state.activeSection : section
      ));
      const newActiveSection = state.sections.find((el) => el.id === action.payload.id);

      return { ...state, sections: setIndexByParam(newSections), activeSection: newActiveSection };
    }

    case ACTIONS.SET_PREVIEW_LIST:
      return { ...state, previewList: action.payload.list };

    case ACTIONS.SET_PREVIEW_ITEM: {
      const newPreviewList = state.previewList.map((preview) => (
        action.payload.item.id === preview.id ? action.payload.item : preview
      ));

      if (action.payload.item.id !== state.activeSection.id) return { ...state, previewList: newPreviewList };

      const newActiveSection = { ...state.activeSection, errors: action.payload.item.errors };

      return { ...state, previewList: newPreviewList, activeSection: newActiveSection };
    }

    case ACTIONS.SET_SECTION_BACKGROUND: {
      const newSections = state.sections.map((section) => {
        if (action.payload.section.id !== section.id) return section;
        return { ...section, ...action.payload.section };
      });

      if (action.payload.section.id !== state.activeSection.id) return { ...state, sections: newSections };

      const newActiveSection = { ...state.activeSection, ...action.payload.section };
      return { ...state, sections: newSections, activeSection: newActiveSection };
    }

    case ACTIONS.SET_SECTION_POSITIONS: {
      const { oldIndex, newIndex } = action.payload;
      const newSections = arrayMove(state.sections, oldIndex, newIndex);

      const idsOrder = newSections.map((section) => section.id);
      const newPreviewList = sortBy(state.previewList, (previewItem) => idsOrder.indexOf(previewItem.id));
      const newChangePositionsCounter = state.changePositionsCounter + 1;

      return {
        ...state,
        sections: setIndexByParam(newSections),
        previewList: newPreviewList,
        changePositionsCounter: newChangePositionsCounter,
      };
    }

    case ACTIONS.EDIT_DESIGN: {
      const {
        type,
        name,
        checked,
        value,
      } = action.payload.event.target;
      const newValue = type === 'checkbox' ? checked : `${value}`;
      const newDesign = { ...state.design, [name]: newValue };

      return { ...state, design: newDesign };
    }

    case ACTIONS.EDIT_ACTIVE_SECTION: {
      const {
        type,
        name,
        checked,
        value,
      } = action.payload.event.target;
      const newValue = type === 'checkbox' ? checked : value;
      const newActiveSection = { ...state.activeSection, [name]: newValue };

      return { ...state, activeSection: newActiveSection };
    }

    case ACTIONS.ADD_SECTION: {
      const { section_item: sectionItem, section_preview: sectionPreview, positions } = action.payload;

      const sectionsWithUpdatedActiveSection = state.sections.map((section) => (
        section.id === state.activeSection.id ? state.activeSection : section
      ));
      const newSections = [...sectionsWithUpdatedActiveSection, sectionItem];
      const newSectionsWithPositions = newSections.map((section) => ({ ...section, position: positions[section.id] }));
      const newSortedSectionsWithPositions = sortBy(newSectionsWithPositions, ['position']);

      const idsOrder = newSortedSectionsWithPositions.map((section) => section.id);
      const newPreviewList = [...state.previewList, sectionPreview];
      const newSortedPreviewList = sortBy(newPreviewList, (previewItem) => idsOrder.indexOf(previewItem.id));

      return {
        ...state,
        sections: setIndexByParam(newSortedSectionsWithPositions),
        previewList: newSortedPreviewList,
        activeSection: sectionItem,
      };
    }

    case ACTIONS.DELETE_SECTION: {
      const newSections = state.sections.filter((section) => (section.id !== action.payload.id));
      const newPreviewList = state.previewList.filter((preview) => (preview.id !== action.payload.id));
      const newActiveSection = action.payload.id === state.activeSection.id ? newSections[0] : state.activeSection;

      return {
        ...state,
        sections: setIndexByParam(newSections),
        previewList: newPreviewList,
        activeSection: newActiveSection,
      };
    }

    case ACTIONS.SET_QUESTION_OPTION: {
      if (state.activeSection.type !== 'question') throw new Error('Incorrect section type');

      const {
        type,
        name,
        checked,
        value,
      } = action.payload.event.target;
      const newValue = type === 'checkbox' ? checked : value;

      const option = state.activeSection.question_options[action.payload.index];
      const newOption = { ...option, [name]: newValue };
      const newOptions = (name === 'correct' && newValue)
        ? state.activeSection.question_options.map((opt) => ({ ...opt, correct: false }))
        : [...state.activeSection.question_options];
      newOptions[action.payload.index] = newOption;
      const newActiveSection = { ...state.activeSection, question_options: newOptions, question_options_attributes: newOptions };

      return { ...state, activeSection: newActiveSection };
    }

    case ACTIONS.ADD_QUESTION_OPTION: {
      if (state.activeSection.type !== 'question') throw new Error('Incorrect section type');

      const newOptions = [...state.activeSection.question_options, action.payload.option];
      const newActiveSection = { ...state.activeSection, question_options: newOptions, question_options_attributes: newOptions };

      return { ...state, activeSection: newActiveSection };
    }

    case ACTIONS.DELETE_QUESTION_OPTION: {
      if (state.activeSection.type !== 'question') throw new Error('Incorrect section type');

      const newOptions = [...state.activeSection.question_options.filter((option) => option.id !== action.payload.id)];
      const newActiveSection = { ...state.activeSection, question_options: newOptions, question_options_attributes: newOptions };

      return { ...state, activeSection: newActiveSection };
    }

    case ACTIONS.SET_QUESTION_OPTION_POSITIONS: {
      if (state.activeSection.type !== 'question') throw new Error('Incorrect section type');

      const { oldIndex, newIndex } = action.payload;
      const newOptions = arrayMove(state.activeSection.question_options, oldIndex, newIndex).map((option, index) => ({ ...option, position: index }));
      const newActiveSection = { ...state.activeSection, question_options: newOptions, question_options_attributes: newOptions };

      return { ...state, activeSection: newActiveSection };
    }

    case ACTIONS.SET_TEMPLATE:
      return { ...state, template: action.payload.template };

    case ACTIONS.SET_LOGO:
      return { ...state, logo: action.payload.logo };

    case ACTIONS.SET_BASE_ERRORS:
      return { ...state, baseErrors: action.payload.errors };

    default:
      throw new Error();
  }
}

export { editorReducer, ACTIONS };
