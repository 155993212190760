import React, { useMemo } from 'react';
import { capitalize, debounce } from 'lodash';
import LogoForm from './logo_form';
import { CustomSelect } from '../shared/custom_select';

function Design(props) {
  const {
    design,
    onChange,
    updateDesign,
    showTemplates,
    removeTemplate,
    template,
    contentId,
    dispatch,
    logo,
  } = props;
  const fonts = {
    helvetica: {
      name: 'Helvetica',
      value: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    times_new_roman: {
      name: 'Times New Roman',
      value: 'TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif',
    },
    courier: {
      name: 'Courier',
      value: 'Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
    },
    arial: {
      name: 'Arial',
      value: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
    },
    open_sans: {
      name: 'Open Sans',
      value: '"Open Sans", sans-serif',
    },
    style_script: {
      name: 'Style Script',
      value: '"Style Script", cursive',
    },
  };
  const alignments = ['left', 'center', 'right'];

  const updateDesignDebounced = useMemo(() => debounce(updateDesign, 500), []);

  const handleOnChange = (event) => {
    onChange(event);
    updateDesignDebounced();
  };

  return (
    <form>
      <div className="mb-4">
        <label htmlFor="design_font_family" className="form-label w-100">
          <div className="mb-2 side-header">Font Family</div>
          <CustomSelect
            valueAsStyle
            inputName="font_family"
            defaultValue={design.font_family}
            options={fonts}
            changeHandler={handleOnChange}
          />
        </label>
      </div>
      <div className="mb-2 side-header">Colors</div>
      <div className="mb-4">
        <label htmlFor="design_background_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_background_color"
            type="color"
            name="background_color"
            value={design.background_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Background color
            <br />
            {design.background_color}
          </span>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="design_text_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_text_color"
            type="color"
            name="text_color"
            value={design.text_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Text color
            <br />
            {design.text_color}
          </span>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="design_button_background_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_button_background_color"
            type="color"
            name="button_background_color"
            value={design.button_background_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Button Color
            <br />
            {design.button_background_color}
          </span>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="design_button_text_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_button_text_color"
            type="color"
            name="button_text_color"
            value={design.button_text_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Button Text Color
            <br />
            {design.button_text_color}
          </span>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="design_input_background_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_input_background_color"
            type="color"
            name="input_background_color"
            value={design.input_background_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Answer Background Color
            <br />
            {design.input_background_color}
          </span>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="design_input_text_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_input_text_color"
            type="color"
            name="input_text_color"
            value={design.input_text_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Answer Text Color
            <br />
            {design.input_text_color}
          </span>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="design_input_active_color" className="form-label d-flex align-items-center w-100">
          <input
            id="design_input_active_color"
            type="color"
            name="input_active_color"
            value={design.input_active_color}
            className="form-control p-0"
            onChange={handleOnChange}
          />
          <span className="flex-shrink-0 ms-3">
            Answer Selected Color
            <br />
            {design.input_active_color}
          </span>
        </label>
      </div>
      <div className="mb-2 side-header">Content Alignment</div>
      <div className="mb-3 row">
        {alignments.map((alignment) => (
          <div className="col-auto" key={alignment}>
            <input
              id={`design_content_alignment_${alignment}`}
              type="radio"
              name="content_alignment"
              value={alignment}
              checked={design.content_alignment === alignment}
              className="radio-input"
              onChange={handleOnChange}
            />
            <label htmlFor={`design_content_alignment_${alignment}`} key={alignment} className="radio-label">
              <svg>
                <use xlinkHref={`#align${capitalize(alignment)}Icon`} />
              </svg>
            </label>
          </div>
        ))}
      </div>
      <LogoForm
        contentId={contentId}
        dispatch={dispatch}
        logo={logo}
      />
      <div className="mb-2 side-header justify-content-between mb-2 d-flex">
        <div>Selected Template:</div>
        <button type="button" className="btn btn-sm btn-link" onClick={showTemplates}>
          Change
        </button>
      </div>
      <div className="template-preview">
        <div className={`template-stamp ${template.url ? '' : 'empty'}`} style={{ backgroundColor: design.background_color }}>
          {template.url
            && <img src={template.url} alt={template.name} />}
          <div className="template-name">
            {template.name ? template.name : 'creating from scratch'}
          </div>
        </div>
        {template.id && (
          <button type="button" className="btn btn-outline-primary ms-3" onClick={removeTemplate}>
            <svg className="quiz-type">
              <use xlinkHref="#deleteIcon" />
            </svg>
          </button>
        )}
      </div>
    </form>
  );
}

export default Design;
