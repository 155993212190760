import React, {
  useEffect, useRef, useReducer, useState,
} from 'react';
import http from '@utils/http';
import hash from 'object-hash';
import { setIndexByParam } from '@utils/helpers';
import { omit } from 'lodash';
import SectionsList from './sections_list';
import PreviewScreen from './preview_screen';
import Design from './design';
import ScreenForm from './screen_form';
import useUpdateEffect from '../../hooks/use_update_effect';
import { editorReducer, ACTIONS } from './editor_reducer';
import TemplateForm from './template_form';
import BackgroundImageForm from './background_image_form';
import TogglerButton from './toggler_button';
import Navigation from './navigation';

function Editor(props) {
  const { content, sections, templates } = props;
  const sectionWithTypeIndex = setIndexByParam(sections);
  const rowRef = useRef(null);
  const previewRef = useRef(null);
  const leftColRef = useRef(null);
  const rightColRef = useRef(null);

  const setDefaultPanelState = () => window.innerWidth > 1200;

  const [leftPanelActive, setLeftPanelActive] = useState(setDefaultPanelState());
  const leftTogglerRef = useRef(null);

  const toggleLeftPanel = () => setLeftPanelActive((prevState) => {
    rowRef.current.classList.toggle('pad-left');

    return !prevState;
  });

  const [rightPanelActive, setRightPanelActive] = useState(setDefaultPanelState());
  const rightTogglerRef = useRef(null);

  const toggleRightPanel = () => setRightPanelActive((prevState) => {
    rowRef.current.classList.toggle('pad-right');

    return !prevState;
  });

  const [state, dispatch] = useReducer(editorReducer, {
    mode: 'content',
    design: content.design,
    sections: sectionWithTypeIndex,
    activeSection: sectionWithTypeIndex[0],
    previewList: [],
    changePositionsCounter: 0,
    template: content.template,
    logo: content.logo,
    baseErrors: [],
  });

  const sectionHash = (section) => hash(omit(section, ['position', 'type_index']));

  const designOnChange = (event) => {
    dispatch({ type: ACTIONS.EDIT_DESIGN, payload: { event } });
  };

  const activeSectionOnChange = (event) => {
    dispatch({ type: ACTIONS.EDIT_ACTIVE_SECTION, payload: { event } });
  };

  const designRef = useRef();
  const activeSectionRef = useRef();
  const sectionHashesRef = useRef({});
  designRef.current = state.design;
  activeSectionRef.current = state.activeSection;

  const updateDesign = () => {
    http.put(`/editor/designs/${state.design.id}`, { design: designRef.current })
      .then((response) => dispatch({ type: ACTIONS.SET_PREVIEW_LIST, payload: { list: response.data.sections } }));
  };

  const setActiveSection = (section) => {
    dispatch({ type: ACTIONS.SET_ACTIVE_SECTION, payload: { section } });
  };

  const updateActiveSection = () => {
    const activeSectionHash = sectionHash(activeSectionRef.current);
    const activeSectionId = activeSectionRef.current.id;

    if (sectionHashesRef.current[activeSectionId] === activeSectionHash) return;

    const route = ({
      welcome: 'welcome_screens',
      question: 'question_screens',
      ending: 'ending_screens',
      product_placement: 'product_placement_screens',
    })[activeSectionRef.current.type];

    http.put(`/editor/${route}/${activeSectionRef.current.screenable_id}`, { screen: activeSectionRef.current })
      .then((response) => {
        dispatch({ type: ACTIONS.SET_PREVIEW_ITEM, payload: { item: response.data.section } });
        sectionHashesRef.current[activeSectionId] = activeSectionHash;
      });
  };

  useEffect(() => {
    state.sections.forEach((section) => {
      sectionHashesRef.current[section.id] = sectionHash(section);
    });
  }, []);

  const fetchPreview = () => {
    http.get(`/editor/contents/${content.id}/sections/preview_list`)
      .then((response) => dispatch({ type: ACTIONS.SET_PREVIEW_LIST, payload: { list: response.data.sections } }));
  };

  const setActivateSectionById = (id) => {
    if (id === state.activeSection.id) return;

    updateActiveSection();
    dispatch({ type: ACTIONS.SET_ACTIVE_SECTION_BY_ID, payload: { id } });
  };

  const updateSectionsPositions = () => {
    const idsOrder = state.sections.map((section) => section.id);
    http.put(`/editor/contents/${content.id}/sections/update_positions`, { ids: idsOrder });
  };

  const updateSidebarsHeight = (event) => {
    if (window.scrollY < 0) return;
    if (!leftColRef.current || !rightColRef.current) return;
    const top = leftColRef.current.getBoundingClientRect().top || rightColRef.current.getBoundingClientRect().top;
    const topOffset = previewRef.current.parentNode.getBoundingClientRect().top;
    if (!event?.type) {
      leftColRef.current.style.top = `${topOffset}px`;
      rightColRef.current.style.top = `${topOffset}px`;
    }
    const newHeight = window.innerHeight - top;
    const newOffset = topOffset <= 0 ? 0 : topOffset;
    leftColRef.current.style.top = `${newOffset}px`;
    leftTogglerRef.current.style.top = `${newOffset}px`;
    leftColRef.current.style.height = `${newHeight}px`;
    rightColRef.current.style.top = `${newOffset}px`;
    rightTogglerRef.current.style.top = `${newOffset}px`;
    rightColRef.current.style.height = `${newHeight}px`;
  };

  useEffect(() => {
    if (setDefaultPanelState()) {
      rowRef.current.classList.add('pad-left', 'pad-right');
    }
    fetchPreview();
    updateSidebarsHeight();
    window.addEventListener('resize', updateSidebarsHeight, false);
    window.addEventListener('scroll', updateSidebarsHeight, false);

    return () => {
      window.addEventListener('resize', updateSidebarsHeight, false);
      window.addEventListener('scroll', updateSidebarsHeight, false);
    };
  }, []);

  useUpdateEffect(() => {
    updateSectionsPositions();
  }, [state.changePositionsCounter]);

  const setSectionsPositions = (oldIndex, newIndex) => {
    dispatch({ type: ACTIONS.SET_SECTION_POSITIONS, payload: { oldIndex, newIndex } });
  };

  const addSection = (type) => {
    http.post(`/editor/contents/${content.id}/sections`, { type })
      .then((response) => dispatch({ type: ACTIONS.ADD_SECTION, payload: response.data }));
  };

  const deleteSection = (id) => {
    dispatch({ type: ACTIONS.DELETE_SECTION, payload: { id } });
    http.delete(`/editor/sections/${id}`);
  };

  const duplicateSection = (id) => {
    http.post(`/editor/sections/${id}/duplicate`)
      .then((response) => dispatch({ type: ACTIONS.ADD_SECTION, payload: response.data }));
  };

  const setTemplate = (template) => {
    dispatch({ type: ACTIONS.SET_TEMPLATE, payload: { template } });
    http.put(`/editor/contents/${content.id}`, { content_template_id: template.id });
  };

  const arbitraryStyles = (sectionId) => {
    const section = state.sections.find((sect) => sect.id === sectionId);
    return {
      templateUrl: (section.background_image_url ? section.background_image_url : state.template.url_bg),
      opacity: (sectionId === state.activeSection.id ? state.activeSection.opacity_percentage : section.opacity_percentage) / 100,
    };
  };

  const verifyContent = () => {
    http.post(`/editor/contents/${content.id}/verify`)
      .then(() => {
        window.location.href = `/content_settings/${content.setting.id}/edit`;
      }).catch((error) => {
        if (error.response.status === 422) {
          dispatch({ type: ACTIONS.SET_BASE_ERRORS, payload: { errors: error.response.data.errors.base } });
        } else {
          throw error;
        }
      });
  };

  const previewItems = state.previewList.map((screen, index) => (
    <PreviewScreen
      screen={screen}
      activeSectionId={state.activeSection?.id}
      key={screen.id}
      index={index}
      type={state.sections.find((s) => s.id === screen.id).type}
      arbitraryStyles={arbitraryStyles(screen.id)}
      updateSidebars={updateSidebarsHeight}
    />
  ));

  const editorModeComponent = () => {
    if (state.mode === 'content' && state.activeSection) {
      const backgroundImageForm = (
        <BackgroundImageForm section={state.activeSection} dispatch={dispatch} key={state.activeSection.id} />
      );
      return (
        <ScreenForm
          section={state.activeSection}
          setSection={setActiveSection}
          sectionOnChange={activeSectionOnChange}
          updateActiveSection={updateActiveSection}
          dispatch={dispatch}
          backgroundImageForm={backgroundImageForm}
        />
      );
    }

    if (state.mode === 'template') {
      return (
        <TemplateForm
          templates={templates}
          currentTemplate={state.template}
          setTemplate={setTemplate}
          setModeDesign={() => { dispatch({ type: ACTIONS.SET_MODE, payload: { mode: 'design' } }); }}
        />
      );
    }
    return (
      <Design
        design={state.design}
        onChange={designOnChange}
        updateDesign={updateDesign}
        showTemplates={() => dispatch({ type: ACTIONS.SET_MODE, payload: { mode: 'template' } })}
        removeTemplate={() => setTemplate({ id: null })}
        template={state.template}
        contentId={content.id}
        dispatch={dispatch}
        logo={state.logo}
      />
    );
  };

  return (
    <>
      <Navigation verifyContent={verifyContent} errors={state.baseErrors} contentId={content.id} />
      <div ref={rowRef} className="row">
        <TogglerButton
          clickHandler={toggleLeftPanel}
          orientation="left"
          isActive={leftPanelActive}
          forwardRef={leftTogglerRef}
          title={`${leftPanelActive ? 'Hide' : 'Show'} view list`}
        />
        <TogglerButton
          clickHandler={toggleRightPanel}
          orientation="right"
          isActive={rightPanelActive}
          forwardRef={rightTogglerRef}
          title={`${rightPanelActive ? 'Hide' : 'Show'} editor panel`}
        />
        <div ref={leftColRef} className={`left-column px-4 py-4 ${leftPanelActive ? 'is-open' : ''}`}>
          <SectionsList
            sections={state.sections}
            activeSectionId={state.activeSection?.id}
            setActivateSectionById={setActivateSectionById}
            setSectionsPositions={setSectionsPositions}
            addSection={addSection}
            deleteSection={deleteSection}
            duplicateSection={duplicateSection}
          />
        </div>
        <div ref={previewRef} className="preview" style={{ overflow: 'auto', minHeight: '620px' }}>
          {previewItems.length ? previewItems : <div>There is no views</div>}
        </div>
        <div ref={rightColRef} className={`right-column px-4 py-4 ${rightPanelActive ? 'is-open' : ''}`}>
          {(state.mode === 'design' || state.mode === 'content') && (
            <div className="editor-tabs">
              { state.activeSection && (
                <button
                  type="button"
                  className={`btn ${state.mode === 'content' && 'active'}`}
                  onClick={() => { dispatch({ type: ACTIONS.SET_MODE, payload: { mode: 'content' } }); }}
                >
                  Content
                </button>
              )}
              <button
                type="button"
                className={`btn ${state.mode === 'design' && 'active'}`}
                onClick={() => { dispatch({ type: ACTIONS.SET_MODE, payload: { mode: 'design' } }); }}
              >
                Design
              </button>
            </div>
          )}
          {editorModeComponent()}
        </div>
      </div>
    </>
  );
}

export default Editor;
