import React, { useState } from 'react';

function TemplateForm(props) {
  const {
    templates,
    currentTemplate,
    setTemplate,
    setModeDesign,
  } = props;

  const [query, setQuery] = useState('');

  const handleUpdateQuery = (event) => {
    const { value } = event.target;
    setQuery(value);
  };

  const filteredTemplates = templates.filter((template) => (
    template.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  ));

  const handleTemplateChange = (template) => {
    if (currentTemplate.id !== template.id) setTemplate(template);
  };

  return (
    <div className="templates-side">
      <div className="side-header mb-1">
        <button
          type="button"
          onClick={setModeDesign}
          className="btn btn-sm btn-light"
        >
          <svg className="back-icon">
            <use xlinkHref="#backArrowIcon" />
          </svg>
        </button>
        <span className="ml-2">Templates</span>
      </div>
      <div className="template-search">
        <input
          type="text"
          name="query"
          value={query}
          placeholder="Search"
          className="form-control"
          onChange={handleUpdateQuery}
        />
      </div>
      <div className="template-examples">
        {filteredTemplates.map((template) => (
          <div className="template-example" key={template.id}>
            <button className="template-stamp template-preview" type="button" onClick={() => handleTemplateChange(template)}>
              <img src={template.url} alt={template.name} className={currentTemplate.id === template.id ? 'border border-info' : ''} />
              <div className="template-name">{template.name}</div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TemplateForm;
