// eslint-disable-next-line consistent-return
export const initCustomSelect = () => {
  const customSelects = document.querySelectorAll('.selected-value');
  if (!customSelects.length) return null;

  const toggleSelect = (event) => {
    event.target.closest('.custom-select').classList.toggle('is-open');
  };

  const closeSelects = () => document.querySelectorAll(('.custom-select')).forEach((select) => {
    select.classList.remove('is-open');
  });

  const handleSelect = (event) => {
    const selectedItem = event.target;
    const parent = selectedItem.closest('.custom-select');
    const valueHolder = parent.querySelector('.selected-value');
    const { mode } = parent.dataset;
    if (mode === 'navigation') {
      const id = document.querySelector(selectedItem.dataset.value);
      const offset = document.querySelector('.side-nav-mobile').getBoundingClientRect().height;
      window.scrollTo({
        top: window.scrollY + id.getBoundingClientRect().y - offset,
        behavior: 'smooth',
      });
    }
    valueHolder.innerHTML = selectedItem.innerHTML;
    toggleSelect(event);
  };

  customSelects.forEach((select) => {
    select.addEventListener('click', toggleSelect);
    select.parentNode.querySelectorAll('.custom-select-list button').forEach((button) => {
      button.addEventListener('click', handleSelect);
    });
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.custom-select')) closeSelects();
    });
  });
};
