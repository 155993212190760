import React from 'react';
import ReactDOM from 'react-dom';
import Editor from '../components/contents/editor';

const initContentEditor = ({ content, sections, templates }) => {
  ReactDOM.render(<Editor content={content} sections={sections} templates={templates} />,
    document.querySelector('#content-editor[data-behavior="react"]'));
};

export default initContentEditor;
