const handleSmoothScroll = (event) => {
  const id = event.target.getAttribute('href');
  if (id === '#' || !id.includes('#')) return;
  document.querySelector(id)?.scrollIntoView({
    behavior: 'smooth',
  });
};

// eslint-disable-next-line consistent-return
export const initSmoothScroll = () => {
  const links = document.querySelectorAll('.js-scroll');
  if (!links.length) return null;
  links.forEach((link) => {
    link.addEventListener('click', handleSmoothScroll);
  });

  document.addEventListener('beforeunload', () => {
    links.forEach((link) => {
      link.removeEventListener('click', handleSmoothScroll);
    });
  });
};

export const hideOnScroll = (elementsSelector = '.mobile-only .hide-on-scroll') => {
  document.addEventListener('scroll', () => {
    const elementsToHide = document.querySelectorAll(elementsSelector);
    if (!elementsToHide.length) return;
    if (window.scrollY >= 50) {
      elementsToHide.forEach((element) => element.classList.add('d-none'));
    }
    if (window.scrollY < 50) {
      elementsToHide.forEach((element) => element.classList.remove('d-none'));
    }
  }, false);
};
