class FormInputs {
  constructor() {
    this.inputs = document.querySelectorAll('.movable-label-wrapper .form-control');
    this.autofocusedInput = document.querySelector('input[autocomplete]:not(.off):not([type="hidden"])');
  }

  static findLabel(input) {
    if (!input) return null;
    return input.parentElement.querySelector('label');
  }

  static addFocused(event) {
    const target = event.target || event;
    FormInputs.findLabel(target).classList.add('focused');
  }

  static removeFocused(event) {
    if (!event.target.value) FormInputs.findLabel(event.target).classList.remove('focused');
  }

  init() {
    if (!this.inputs.length) return;
    if (this.autofocusedInput) {
      FormInputs.addFocused(this.autofocusedInput);
      this.autofocusedInput.focus();
    }
    this.inputs.forEach((input) => {
      if (input.value.trim()) FormInputs.addFocused(input);
      input.addEventListener('focus', FormInputs.addFocused);
      input.addEventListener('change', FormInputs.addFocused);
      input.addEventListener('blur', FormInputs.removeFocused);
    });

    document.addEventListener('beforeunload', () => {
      this.inputs.forEach((input) => {
        input.removeEventListener('focus', FormInputs.addFocused);
        input.removeEventListener('change', FormInputs.addFocused);
        input.removeEventListener('blur', FormInputs.removeFocused);
      });
    });
  }
}

export default FormInputs;
