const handleCopyToClipboard = () => {
  $('.copy-to-clipboard').on('click', (e) => {
    const link = e.target.getAttribute('data-content');
    navigator.clipboard.writeText(link);

    const modal = new window.bootstrap.Toast('#copied-to-clipboard', { delay: 5000 });
    modal.show();
  });
};

export default handleCopyToClipboard;
