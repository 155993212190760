import React, { useRef, useEffect } from 'react';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';

function EndingScreen(props) {
  const { section, sectionOnChange, backgroundImageForm } = props;
  const { errors } = section;

  const refHeader = useRef();
  const refDescription = useRef();

  useEffect(() => {
    refHeader.current.editor.loadHTML(section.header);
    refDescription.current.editor.loadHTML(section.description);
  }, [section.id]);

  return (
    <form>
      <div className="mb-4">
        <div className="mb-2 side-header">Header</div>
        <div className="screen-field">
          <ReactTrixRTEToolbar
            toolbarId="screen_header"
            toolbarActions={['bold', 'italic', 'quote']}
          />
          <ReactTrixRTEInput
            id={`screen_header_${section.id}`}
            name="header"
            toolbarId="screen_header"
            trixInputRef={refHeader}
            className="form-control"
            onChange={sectionOnChange}
          />
        </div>
        {errors && errors.header && (
          <div className="input-error">{errors.header[0]}</div>
        )}
      </div>
      <div className="mb-4">
        <div className="mb-2 side-header">Description</div>
        <div className="screen-field">
          <ReactTrixRTEToolbar
            toolbarId="screen_description"
            toolbarActions={['bold', 'italic', 'quote', 'bullet', 'number']}
          />
          <ReactTrixRTEInput
            id={`screen_description_${section.id}`}
            name="description"
            toolbarId="screen_description"
            trixInputRef={refDescription}
            className="form-control"
            onChange={sectionOnChange}
          />
        </div>
        {errors && errors.description && (
          <div className="input-error">{errors.description[0]}</div>
        )}
      </div>
      {backgroundImageForm}
      <div className="mb-4">
        <label htmlFor="screen_button_text" className="form-label w-100">
          <div className="mb-2 side-header">Button text</div>
          <input
            id="screen_button_text"
            type="text"
            name="button_text"
            value={section.button_text || ''}
            className="form-control"
            onChange={sectionOnChange}
          />
        </label>
        <br />
        {errors && errors.button_text && (
          <span className="input-error">{errors.button_text[0]}</span>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="screen_opacity" className="form-label w-100">
          <span className="mb-2 side-header">Opacity(%)</span>
          <div className="d-flex align-items-center w-100">
            <input
              id="screen_opacity"
              type="range"
              min="0"
              max="100"
              step="5"
              name="opacity_percentage"
              value={section.opacity_percentage}
              className="form-range flex-grow-1 w-auto"
              onChange={sectionOnChange}
            />
            <input
              name="opacity_percentage"
              type="number"
              min="0"
              max="100"
              step="1"
              value={section.opacity_percentage}
              onChange={sectionOnChange}
              className="form-control w-30 ms-4"
            />
          </div>
        </label>
      </div>
    </form>
  );
}

export default EndingScreen;
