// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import './sentry';

import FormInputs from '../src/form_inputs';
import { initSmoothScroll } from '../src/js_scroll';
import { initSideNavObserver } from '../src/intersection_observer';
import { initCustomSelect } from '../src/custom_select';
import handleCopyToClipboard from '../utils/handle_copy_to_clipboard';
import initContentEditor from '../initializers/initializeContentEditor';
import { initTooltips } from '../src/tooltips';

Rails.start();
ActiveStorage.start();

require('@popperjs/core');
window.bootstrap = require('bootstrap');
require('trix');
require('@rails/actiontext');

document.addEventListener('DOMContentLoaded', () => {
  const devicePage = new FormInputs();
  devicePage.init();
  initSmoothScroll();
  initSideNavObserver();
  initCustomSelect();
  handleCopyToClipboard();
  initTooltips();
});

window.initContentEditor = initContentEditor;
