/* eslint-disable react/no-danger */
import React, { useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

function PreviewScreen(props) {
  const {
    screen,
    activeSectionId,
    arbitraryStyles,
    index,
    type,
    updateSidebars,
  } = props;
  const myRef = useRef(null);

  useEffect(() => {
    if (activeSectionId === screen.id) {
      myRef.current.scrollIntoView();
      if (typeof (updateSidebars) === 'function') updateSidebars();
    }
  }, [activeSectionId, index]);

  return (
    <div className="mb-4">
      <div ref={myRef} key={screen.id}>
        <div className={`quiz-view ${type.split('_').join('-')}`}>
          {arbitraryStyles.templateUrl && (
            <img
              alt=""
              src={arbitraryStyles.templateUrl}
              loading="lazy"
              className="quiz-bg"
              style={{ opacity: arbitraryStyles.opacity }}
            />
          )}
          <div
            className={screen.id === activeSectionId ? 'border border-primary quiz-body' : 'quiz-body'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(screen.preview, { ADD_ATTR: ['onclick', 'onsubmit'] }) }}
          />
        </div>
      </div>
    </div>
  );
}

export default PreviewScreen;
