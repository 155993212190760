import React from 'react';

const TogglerButton = ({
  clickHandler, orientation, isActive, title, forwardRef,
}) => (
  <button
    type="button"
    onClick={clickHandler}
    className={`btn toggle-button ${orientation} ${isActive ? 'active' : ''}`}
    ref={forwardRef}
    data-bs-toggle="tooltip"
    title={title}
  >
    <svg className={`dropdown-icon ${orientation} ${isActive ? 'active' : ''}`}>
      <use xlinkHref="#dropdownIcon" />
    </svg>
  </button>
);

export default TogglerButton;
