import React, { useRef, useEffect } from 'react';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';

function WelcomeScreen(props) {
  const { section, sectionOnChange, backgroundImageForm } = props;
  const { errors } = section;

  const refHeader = useRef();
  const refDescription = useRef();

  useEffect(() => {
    refHeader.current.editor.loadHTML(section.header);
    refDescription.current.editor.loadHTML(section.description);
  }, [section.id]);

  return (
    <form className="py-2">
      <div className="mb-4">
        <div className="mb-2 side-header">Header</div>
        <div className="screen-field">
          <ReactTrixRTEToolbar
            toolbarId="screen_header"
            toolbarActions={['bold', 'italic', 'quote']}
          />
          <ReactTrixRTEInput
            id={`screen_header_${section.id}`}
            name="header"
            toolbarId="screen_header"
            trixInputRef={refHeader}
            className="form-control"
            onChange={sectionOnChange}
          />
        </div>
        {errors && errors.header && (
          <div className="input-error">{errors.header[0]}</div>
        )}
      </div>
      <div className="mb-4">
        <div className="mb-2 side-header">Description</div>
        <div className="screen-field">
          <ReactTrixRTEToolbar
            toolbarId="screen_description"
            toolbarActions={['bold', 'italic', 'quote', 'bullet', 'number']}
          />
          <ReactTrixRTEInput
            id={`screen_description_${section.id}`}
            name="description"
            toolbarId="screen_description"
            trixInputRef={refDescription}
            className="form-control"
            onChange={sectionOnChange}
          />
        </div>
        {errors && errors.description && (
          <div className="input-error">{errors.description[0]}</div>
        )}
      </div>
      {backgroundImageForm}
      <div className="mb-4">
        <label htmlFor="screen_button_text" className="form-label w-100">
          <div className="mb-2 side-header">Button text</div>
          <input
            id="screen_button_text"
            type="text"
            name="button_text"
            value={section.button_text || ''}
            className="form-control"
            onChange={sectionOnChange}
          />
        </label>
        <br />
        {errors && errors.button_text && (
          <div className="input-error">{errors.button_text[0]}</div>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="screen_opacity" className="form-label w-100">
          <span className="mb-2 side-header">Opacity(%)</span>
          <div className="d-flex align-items-center w-100">
            <input
              id="screen_opacity"
              type="range"
              min="0"
              max="100"
              step="5"
              name="opacity_percentage"
              value={section.opacity_percentage}
              className="form-range  flex-grow-1 w-auto"
              onChange={sectionOnChange}
            />
            <input
              name="opacity_percentage"
              type="number"
              min="0"
              max="100"
              step="1"
              value={section.opacity_percentage}
              onChange={sectionOnChange}
              className="form-control w-30 ms-4"
            />
          </div>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="screen_age_verification" className="form-label w-100">
          <div className="form-check form-switch d-flex justify-content-between w-100 ps-0">
            <label className="form-check-label" htmlFor="screen_age_verification">Age verification</label>
            <input
              className="form-check-input"
              type="checkbox"
              id="screen_age_verification"
              name="age_verification"
              checked={section.age_verification}
              onChange={sectionOnChange}
            />
          </div>
        </label>
      </div>
      {section.age_verification && (
        <div className="mb-4">
          <label htmlFor="screen_minimal_age" className="form-label d-flex align-items-center justify-content-between w-100">
            <div className="mb-2 w-auto">Minimal age</div>
            <input
              id="screen_minimal_age"
              type="number"
              min="0"
              max="100"
              step="1"
              name="minimal_age"
              value={section.minimal_age}
              className="form-control w-30"
              onChange={sectionOnChange}
            />
          </label>
          {errors && errors.minimal_age && (
            <span className="input-error">{errors.minimal_age[0]}</span>
          )}
        </div>
      )}
    </form>
  );
}

export default WelcomeScreen;
