export const thumbsContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

export const thumbStyle = {
  display: 'inline-flex',
  border: '1px solid #eaeaea',
  borderRadius: '.6rem',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

export const thumbInnerStyle = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

export const imgStyle = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
