import React, { useState, useEffect, useRef } from 'react';

export const CustomSelect = ({
  valueAsStyle = false, inputName = 'custom_select', defaultValue = '', options, changeHandler,
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    setSelectedOption(options[defaultValue]);
    const toggleSelect = (event) => {
      if ((
        !$(event.target).closest(wrapperRef.current).length
        && !$(event.target).is(wrapperRef.current)
      )) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', toggleSelect, false);
    return () => {
      document.removeEventListener('click', toggleSelect, false);
    };
  }, []);

  const createOptions = () => Object.entries(options).map(([key, option]) => {
    const { name, value } = option;
    return (
      <li key={key}>
        <button
          type="button"
          data-value={value}
          style={{
            fontFamily: valueAsStyle ? value : 'inherit',
          }}
          onClick={() => {
            setIsOpen(false);
            setSelectedOption(option);
            const customEvObj = {
              target: {
                name: inputName,
                value,
              },
            };
            changeHandler(customEvObj);
          }}
        >
          {name}
        </button>
      </li>
    );
  });

  return (
    <div ref={wrapperRef} className="custom-select">
      <button
        type="button"
        onClick={() => setIsOpen((prevState) => !prevState)}
        style={{
          fontFamily: valueAsStyle ? selectedOption?.value : 'inherit',
        }}
        className="form-control form-select"
      >
        {selectedOption?.name || 'Select font'}
      </button>
      <ul className={`custom-select-options ${isOpen ? 'is-open' : ''}`}>
        {createOptions()}
      </ul>
    </div>
  );
};
