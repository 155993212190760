import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'bootstrap';

function QuestionOption(props) {
  const {
    option,
    handleUpdateOption,
    deleteOption,
    dragHandle,
    errors,
  } = props;

  const tooltipRef = useRef(null);

  useEffect(() => {
    const _tooltip = new Tooltip(tooltipRef.current);
  }, []);

  return (
    <div className="row align-items-center mb-3">
      <div className="col-auto">
        {dragHandle}
      </div>
      <div className="col-auto">
        <input
          type="checkbox"
          name="correct"
          checked={option.correct || false}
          className="form-check-input"
          onChange={handleUpdateOption}
          data-bs-toggle="tooltip"
          title="Select to set this option as correct"
          ref={tooltipRef}
        />
      </div>
      <div className="col">
        <input
          type="text"
          name="value"
          value={option.value || ''}
          className="form-control"
          onChange={handleUpdateOption}
        />
      </div>
      <div className="col-auto">
        <button type="button" className="btn btn-outline-primary" onClick={deleteOption}>
          <svg className="quiz-type">
            <use xlinkHref="#deleteIcon" />
          </svg>
        </button>
      </div>
      {errors && errors.value && (
        <div className="input-error">{errors.value[0]}</div>
      )}
    </div>
  );
}

export default QuestionOption;
