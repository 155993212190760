import React, { useMemo } from 'react';
import { throttle } from 'lodash';
import WelcomeScreen from './screens/welcome_screen';
import QuestionScreen from './screens/question_screen';
import EndingScreen from './screens/ending_screen';
import ProductPlacementScreen from './screens/product_placement_screen';

function ScreenForm(props) {
  const {
    section,
    setSection,
    sectionOnChange,
    updateActiveSection,
    dispatch,
    backgroundImageForm,
  } = props;

  const updateScreenThrottled = useMemo(() => throttle(updateActiveSection, 1000, { leading: false }), []);

  const handleOnChange = (event) => {
    sectionOnChange(event);
    updateScreenThrottled();
  };

  const ScreenComponent = ({
    welcome: WelcomeScreen,
    question: QuestionScreen,
    ending: EndingScreen,
    product_placement: ProductPlacementScreen,
  })[section.type];

  return (
    <div>
      <ScreenComponent
        section={section}
        setSection={setSection}
        sectionOnChange={handleOnChange}
        updateScreen={updateScreenThrottled}
        dispatch={dispatch}
        backgroundImageForm={backgroundImageForm}
      />
    </div>
  );
}

export default ScreenForm;
