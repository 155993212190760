/* eslint-disable no-param-reassign */
import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common.Accept = 'application/json';

    config.headers.common['X-CSRF-Token'] = document.querySelector(
      'meta[name=csrf-token]',
    ).content;

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/users/sign_in?unauthorized=true';
    } else if (error.response.status === 403) {
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

export default axios;
