/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import http from '@utils/http';
import { omit } from 'lodash';
import { ACTIONS } from './editor_reducer';

import {
  thumbsContainerStyle, thumbStyle, thumbInnerStyle, imgStyle,
} from './drag_drop_styles';

function BackgroundImageForm(props) {
  const { section, dispatch } = props;
  const [files, setFiles] = useState(
    section.background_image_url ? [{
      name: 'preview',
      preview: section.background_image_url,
    }] : [],
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 1,
    multiple: false,
    maxSize: 1000000,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })));
    },
    onDropAccepted: (acceptedFiles) => {
      const formData = new FormData();
      formData.append('section[background_image]', acceptedFiles[0]);
      http.put(`/editor/sections/${section.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          const newSection = { ...response.data, errors: omit(section.errors, ['background_image']) };
          dispatch({ type: ACTIONS.SET_SECTION_BACKGROUND, payload: { section: newSection } });
        }).catch((error) => {
          if (error.response.status === 422) {
            const errors = { ...section.errors, ...error.response.data.errors };
            const newSection = { ...error.response.data, errors };
            dispatch({ type: ACTIONS.SET_SECTION_BACKGROUND, payload: { section: newSection } });
          } else {
            throw error;
          }
        });
    },
  });

  const removeTemplate = (id) => {
    http.delete(`/editor/sections/${section.id}/delete_background_image`)
      .then(() => {
        const newSection = { id, background_image_tile_url: null, background_image_url: null };
        dispatch({ type: ACTIONS.SET_SECTION_BACKGROUND, payload: { section: newSection } });
        setFiles([]);
      });
  };

  const thumbs = files.map((file) => (
    <div style={thumbStyle} key={file.name}>
      <div style={thumbInnerStyle}>
        <img
          src={file.preview}
          style={imgStyle}
          alt=""
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="mb-4">
      <div className="mb-2 side-header">Background image</div>

      <div {...getRootProps({ className: 'dropzone' })}>
        <input id="bgImage" name="bgImage" {...getInputProps()} />
        <div className="file-label">
          <p>
            Drag & Drop or
            {' '}
            <span>Browse</span>
          </p>
          png, jpg, size: max 1MB
        </div>
      </div>
      <div className="template-preview">
        <aside style={thumbsContainerStyle}>
          {thumbs}
        </aside>
        {(section.background_image_tile_url || files.length > 0) && (
          <button type="button" className="btn btn-light" onClick={() => removeTemplate(section.id)}>
            <svg className="quiz-type">
              <use xlinkHref="#deleteIcon" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default BackgroundImageForm;
