export const initSideNavObserver = () => {
  const targets = document.querySelectorAll('.observable');
  if (!targets.length) return;
  function buildThresholdList() {
    const thresholds = [];
    const numSteps = 20;

    for (let i = 1.0; i <= numSteps; i += 1) {
      const ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  }

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: buildThresholdList(),
  };

  // eslint-disable-next-line no-unused-vars
  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      const { id } = entry.target;
      const sidenav = document.querySelector('.side-nav');
      if (!sidenav) return;
      if (entry.intersectionRatio > 0.7) {
        sidenav.querySelectorAll('a').forEach((link) => {
          link.classList.remove('active');
        });
        sidenav.querySelector(`[href="#${id}"]`).classList.add('active');
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  targets.forEach((item) => {
    if (item) {
      observer.observe(item);
    }
  });
};
