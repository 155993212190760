import React from 'react';

const Dropdown = ({
  id, togglerClasses, togglerLabel, children, paddedMenu, menuPosition, wrapperClasses,
}) => (
  <div className={`dropdown ${wrapperClasses}`}>
    {
      togglerLabel === '+ Add View'
        ? (
          <div className="p-2">
            <svg className="quiz-type" style={{ opacity: 0, pointerEvents: 'none' }}>
              <use xlinkHref="#dragIcon" />
            </svg>
          </div>
        )
        : false
    }
    <button
      type="button"
      className={`dropdown-toggle ${togglerClasses}`}
      id={id}
      data-bs-toggle="dropdown"
    >
      {togglerLabel}
    </button>
    <ul
      className={`dropdown-menu ${paddedMenu ? 'padded' : ''} ${menuPosition && `dropdown-menu-${menuPosition}`}`}
      aria-labelledby={id}
    >
      {children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`dropdown-item-${index}`}>
          {child}
        </li>
      ))}
    </ul>
  </div>
);

export default Dropdown;
