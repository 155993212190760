import React, { useRef, useEffect } from 'react';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import http from '@utils/http';
import QuestionOption from './question_option';
import { ACTIONS } from '../editor_reducer';

const DragHandle = sortableHandle(() => (
  <svg className="quiz-type mb-1" style={{ cursor: 'grab' }}>
    <use xlinkHref="#dragIcon" />
  </svg>
));

const SortableItem = sortableElement(({
  option,
  position,
  handleUpdateOption,
  deleteOption,
  errors,
}) => (
  <li>
    <QuestionOption
      key={option.id}
      option={option}
      handleUpdateOption={(event) => handleUpdateOption(event, position)}
      deleteOption={() => deleteOption(option.id)}
      dragHandle={<DragHandle />}
      errors={errors}
    />
  </li>
));

const SortableContainer = sortableContainer(({ children }) => <ul className="list-group list-unstyled">{children}</ul>);

function QuestionScreen(props) {
  const {
    section,
    sectionOnChange,
    updateScreen,
    backgroundImageForm,
    dispatch,
  } = props;
  const { errors } = section;

  const refTitle = useRef();
  const refDescription = useRef();

  useEffect(() => {
    refTitle.current.editor.loadHTML(section.title);
    refDescription.current.editor.loadHTML(section.description);
  }, [section.id]);

  const handleUpdateOption = (event, index) => {
    dispatch({ type: ACTIONS.SET_QUESTION_OPTION, payload: { event, index } });
    updateScreen();
  };

  const createOption = () => {
    http.post(`/editor/question_screens/${section.screenable_id}/question_options`)
      .then((response) => {
        dispatch({ type: ACTIONS.ADD_QUESTION_OPTION, payload: { option: response.data } });
      });
  };

  const deleteOption = (id) => {
    dispatch({ type: ACTIONS.DELETE_QUESTION_OPTION, payload: { id } });
    http.delete(`/editor/question_options/${id}`)
      .then((response) => dispatch({ type: ACTIONS.SET_PREVIEW_ITEM, payload: { item: response.data.section } }));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch({ type: ACTIONS.SET_QUESTION_OPTION_POSITIONS, payload: { oldIndex, newIndex } });
    updateScreen();
  };

  const errorsForOption = (errs, opt) => errs && errs.question_options && Object.assign({}, ...errs.question_options)[opt.id];

  return (
    <form>
      <div className="mb-4">
        <div className="mb-2 side-header">Title</div>
        <div className="screen-field">
          <ReactTrixRTEToolbar
            toolbarId="screen_title"
            toolbarActions={['bold', 'italic', 'quote']}
          />
          <ReactTrixRTEInput
            id={`screen_title_${section.id}`}
            name="title"
            toolbarId="screen_title"
            trixInputRef={refTitle}
            className="form-control"
            onChange={sectionOnChange}
          />
        </div>
        {errors && errors.title && (
          <div className="input-error">{errors.title[0]}</div>
        )}
      </div>
      <div className="mb-4">
        <div className="mb-2 side-header">Description</div>
        <div className="screen-field">
          <ReactTrixRTEToolbar
            toolbarId="screen_description"
            toolbarActions={['bold', 'italic', 'quote', 'bullet', 'number']}
          />
          <ReactTrixRTEInput
            id={`screen_description_${section.id}`}
            name="description"
            toolbarId="screen_description"
            trixInputRef={refDescription}
            className="form-control"
            onChange={sectionOnChange}
          />
        </div>
        {errors && errors.description && (
          <div className="input-error">{errors.description[0]}</div>
        )}
      </div>
      <div className="mb-4">
        <div className="mb-2 side-header">Options</div>
        <SortableContainer onSortEnd={onSortEnd} lockAxis="y" useDragHandle helperClass="draggable-item">
          {section.question_options.map((option, index) => (
            <SortableItem
              key={`option-${option.id}`}
              index={index}
              position={index}
              option={option}
              handleUpdateOption={handleUpdateOption}
              deleteOption={deleteOption}
              errors={errorsForOption(errors, option)}
            />
          ))}
        </SortableContainer>
        {section.question_options.length < 4 && (
          <button type="button" className="btn btn-primary mb-2" onClick={createOption}>+ Add Option</button>
        )}
      </div>
      {backgroundImageForm}
      <div className="mb-4">
        <label htmlFor="screen_button_text" className="form-label w-100">
          <div className="mb-2 side-header">Button text</div>
          <input
            id="screen_button_text"
            type="text"
            name="button_text"
            value={section.button_text || ''}
            className="form-control"
            onChange={sectionOnChange}
          />
        </label>
        <br />
        {errors && errors.button_text && (
          <span className="input-error">{errors.button_text[0]}</span>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="screen_opacity" className="form-label w-100">
          <span className="mb-2 side-header">Opacity(%)</span>
          <div className="d-flex align-items-center w-100">
            <input
              id="screen_opacity"
              type="range"
              min="0"
              max="100"
              step="5"
              name="opacity_percentage"
              value={section.opacity_percentage}
              className="form-range flex-grow-1 w-auto"
              onChange={sectionOnChange}
            />
            <input
              name="opacity_percentage"
              type="number"
              min="0"
              max="100"
              step="1"
              value={section.opacity_percentage}
              onChange={sectionOnChange}
              className="form-control w-30 ms-4"
            />
          </div>
        </label>
      </div>
      <div className="mb-4">
        <label htmlFor="screen_countdown_enabled" className="form-label w-100">
          <div className="form-check form-switch d-flex justify-content-between w-100 ps-0">
            <label className="form-check-label" htmlFor="screen_countdown_enabled">Add countdown timer</label>
            <input
              className="form-check-input"
              type="checkbox"
              id="screen_countdown_enabled"
              name="countdown_enabled"
              checked={section.countdown_enabled}
              onChange={sectionOnChange}
            />
          </div>
        </label>
      </div>
      {section.countdown_enabled && (
        <div className="mb-4">
          <label htmlFor="screen_countdown_seconds" className="form-label d-flex align-items-center justify-content-between w-100">
            <span className="mb-2 d-block  flex-grow-1 w-auto">Countdown time (sec)</span>
            <input
              id="screen_countdown_seconds"
              type="number"
              min="1"
              max="3600"
              step="1"
              name="countdown_seconds"
              value={section.countdown_seconds || 1}
              className="form-control ms-4 w-30"
              onChange={sectionOnChange}
            />
          </label>
          {errors && errors.countdown_seconds && (
            <span className="input-error">{errors.countdown_seconds[0]}</span>
          )}
        </div>
      )}
    </form>
  );
}

export default QuestionScreen;
